

















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EntityTypes } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import DataItemInfoDateItem from '@/components/partials/DataItemInfoDateItem.vue';
import DataItemInfoOpeningHoursItem from '@/components/partials/DataItemInfoOpeningHoursItem.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';

@Component({
  name: 'DataItemInfo',
  components: { TheArrowHorizIcon, DataItemInfoOpeningHoursItem, DataItemInfoDateItem },
})
export default class DataItemInfo extends Vue {
  @Prop() item!: any;

  isMobile = window.innerWidth < 768;
  needAllDateList = true;
  needAllDesc = true;

  get dataType() {
    return this.item.__typename;
  }

  get showDateTimes() {
    return (
      this.dataType !== EntityTypes.exhibition &&
      this.dataType !== EntityTypes.art_trip &&
      this.dataType !== EntityTypes.mini_cultivist
    );
  }

  get showAccessInstructionsLink() {
    return (
      this.dataType !== EntityTypes.art_space &&
      this.dataType !== EntityTypes.historical_site &&
      this.item.accessInstructions &&
      this.item.accessInstructions.length
    );
  }

  get openingHourSeasons() {
    return this.dataType === EntityTypes.exhibition && this.item.artSpace
      ? this.item.artSpace.openingHourSeasons
      : this.item.openingHourSeasons;
  }

  get isReviewable() {
    return !isNaN(this.item.memberReviewsCount);
  }

  toggleDesc() {
    this.needAllDesc = !this.needAllDesc;
  }

  toggleDateList() {
    this.needAllDateList = !this.needAllDateList;
  }

  goToReviews() {
    this.$router.push(`${this.$route.path}/reviews`);
  }

  handleShowAccessInstructions() {
    this.$emit('show-access-instructions');
  }

  getImage(data: any) {
    return new ImageHandler().getFirstImgFromMedia(data);
  }
}
